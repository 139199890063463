import React, { useState } from "react";
import { Link } from "gatsby";
import firebase from "gatsby-plugin-firebase"

import PageWrapper from "../components/PageWrapper";
import imgP from "../assets/image/svg/profile-color.svg";


const AffiliateProfile = (props) => {
    let user = {};
    const [affiliatedata, setAffiliatedata] = useState({});

    function getProfileData() {
        firebase
            .firestore()
            .collection('affiliates')
            .where('Email', '==', user.email)
            .get()
            .then(query => {
                const snapshot = query.docs[0];
                setAffiliatedata(snapshot.data());
                console.log(affiliatedata);
            })
            .catch((error) => {
                console.error("Error fetching profile document: ", error);
            });
    }

    React.useEffect(() => {
        firebase.auth().onAuthStateChanged(currentUser => {
            user = currentUser;
            getProfileData();
        });
    }, []);

    return (
        <PageWrapper
            headerConfig={{
                button: "profile",
                isFluid: true,
                bgClass: "bg-default",
                reveal: false,
            }}
        >
            <div className="container position-relative mt-24">
                <div className="bg-white rounded-8 overflow-hidden">
                    <div className="row no-gutters">
                        {/* <!-- Left Sidebar Start --> */}
                        <div className="col-12 col-xl-4 col-lg-4 col-md-5 mb-13 mb-lg-0 border-right border-mercury">

                            <div {...props}>
                                <div className="pl-lg-5">
                                    {/* <!-- Top Start --> */}
                                    <div className="bg-white shadow-9 rounded-4" style={{paddingBottom:"256px"}}>
                                        <div className="px-5 py-11 text-center border-bottom border-mercury">
                                            <Link to="/#" className="mb-4">
                                                <img className="circle-54" src={imgP} alt="" />
                                            </Link>
                                            <h4 className="mb-0">
                                                <Link
                                                    to="/#"
                                                    className="text-black-2 font-size-6 font-weight-semibold"
                                                >
                                                    {affiliatedata.YourName}
                                                </Link>
                                            </h4>
                                            <p className="mb-8">
                                                <Link to="/#" className="text-gray font-size-4">
                                                    {affiliatedata.Designation}
                                                </Link>
                                            </p>
                                        </div>
                                        {/* <!-- Top End --> */}
                                        {/* <!-- Bottom Start --> */}
                                        <div className="px-9 pt-lg-5 pt-9 pt-xl-9 pb-5">
                                            <h5 className="text-black-2 mb-8 font-size-5">Contact Info</h5>
                                            {/* <!-- Single List --> */}
                                            <div className="mb-7">
                                                <p className="font-size-4 mb-0">E-mail</p>
                                                <h5 className="font-size-4 font-weight-semibold mb-0">
                                                    <a
                                                        className="text-black-2 text-break"
                                                        href="mailto:name_ac@gmail.com"
                                                    >
                                                        {affiliatedata.Email}
                                                    </a>
                                                </h5>
                                            </div>
                                            {/* <!-- Single List --> */}
                                            {/* <!-- Single List --> */}
                                            <div className="mb-7">
                                                <p className="font-size-4 mb-0">Phone</p>
                                                <h5 className="font-size-4 font-weight-semibold mb-0">
                                                    <a className="text-black-2 text-break" href="tel:+999565562">
                                                        {affiliatedata.YourIsd} - {affiliatedata.YourPhone}
                                                    </a>
                                                </h5>
                                            </div>
                                            {/* <!-- Single List --> */}
                                        </div>
                                        {/* <!-- Bottom End --> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* <!-- Left Sidebar End --> */}
                        {/* <!-- Middle Content --> */}
                        <div className="col-12 col-xl-8 col-lg-8 col-md-7 order-2 order-lg-1 border-mercury">
                            <div className="bg-white rounded-4 overflow-auto ">
                                {/* <!-- Excerpt Start --> */}
                                <div className="pr-xl-0 pr-xxl-14 p-5 px-xs-12 pt-7 pb-3">
                                    <h4 className="font-size-6 font-weight-semibold mb-3 mt-5 text-black-2">
                                        Organization
                                    </h4>
                                    <p className="font-size-4 font-weight-semibold mb-4">
                                        {affiliatedata.YourOrg}
                                    </p>
                                </div>

                                {/* <!-- Single List --> */}
                                {
                                    <div className="pr-xl-0 pr-xxl-14 p-5 px-xs-12 pt-7 pb-3">
                                        <h4 className="font-size-4 font-weight-semibold mb-3 mt-5 text-black-2">
                                            Website
                                        </h4>
                                        <h5 className="font-size-4 font-weight-semibold mb-0">
                                            <a href={"http://" + affiliatedata.Website} target="_blank" className="text-break">
                                                {affiliatedata.YourOrgWebsite}
                                            </a>
                                        </h5>
                                    </div>
                                }

                                <div className="pr-xl-0 pr-xxl-14 p-5 px-xs-12 pt-7 pb-3">
                                    <h4 className="font-size-4 font-weight-semibold mb-3 mt-5 text-black-2">
                                        Address
                                    </h4>
                                    <p className="font-size-4 mb-4">
                                        {affiliatedata.Address}
                                    </p>
                                    <p className="font-size-4 mb-4">
                                        {affiliatedata.Street}
                                    </p>
                                    <p className="font-size-4 mb-4">
                                        {affiliatedata.City}
                                    </p>
                                    <p className="font-size-4 mb-4">
                                        {affiliatedata.Country}
                                    </p>
                                </div>

                                <div className="pr-xl-0 pr-xxl-14 p-5 px-xs-12 pt-7 pb-3">
                                    <h4 className="font-size-4 font-weight-semibold mb-3 mt-5 text-black-2">
                                        Industries
                                    </h4>
                                    <p className="font-size-4 mb-4">
                                        {affiliatedata.Industries}
                                    </p>
                                </div>

                                <div className="pr-xl-0 pr-xxl-14 p-5 px-xs-12 pt-7 pb-3">
                                    <h4 className="font-size-4 font-weight-semibold mb-3 mt-5 text-black-2">
                                        Technologies
                                    </h4>
                                    <p className="font-size-4 mb-4">
                                        {affiliatedata.Technologies}
                                    </p>
                                </div>
                                {/* <!-- Excerpt End --> */}
                                {/* <!-- Card Section End --> */}
                            </div>
                        </div>
                        {/* <!-- Right Sidebar End --> */}
                    </div>
                </div>
            </div>
        </PageWrapper>
    );
};

export default AffiliateProfile;
